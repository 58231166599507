import { gql } from "@apollo/client";
import { ResourceBookingType } from "~/types/ResourceBookingType";
import { ResourceType } from "~/types/ResourceType";
import { Team } from "~/types/Teams";

export type CoworkerBookings = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;
  startDateTime: string;
  durationInMinutes: number;
  teams?: Team[];
};

export class CoworkerResourceBookings {
  workspace: BookingWorkspace;
  resourceId: string;
  resourceType: ResourceType;
  tags: string[];
  bookingType: ResourceBookingType;
  resourceName: string;
  resourceStatus: string;
  date: string;
  coworkers: CoworkerBookings[];
}

export class BookingWorkspace {
  id: string;
  name: string;
}

export type GetCommunityBookingsParams = {
  meId: string;
  fromDate: string;
};

export const GET_COMMUNITY_BOOKINGS = gql`
  query GetCommunityBookings($meId: String!, $fromDate: String!) {
    communityBookings {
      resourceId
      resourceType
      resourceName
      resourceStatus
      tags
      bookingType
      workspace {
        id
        name
      }
      date
      coworkers {
        userId
        firstName
        lastName
        email
        profilePicture
        startDateTime
        durationInMinutes
      }
    }
    userBookings(userId: $meId, fromDate: $fromDate) {
      durationInMinutes
      startDateTime
      resource {
        id
      }
      cancelledAt
    }
  }
`;
