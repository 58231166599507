import { gql } from '@apollo/client';

export type BookingInput = {
  resourceId: string;
  paymentMethodId: string;
  voucher?: string;
  startDateTime: string;
  durationInMinutes: number;
  organizationId: string;
};

export type CreateBookingMutationInputType = {
  booking: BookingInput;
};

export type CreateBookingMutationType = {
  createBooking: { id: string };
};

export const CREATE_BOOKING_MUTATION = gql`
  mutation CreateBooking($booking: BookingInput!) {
    createBooking(booking: $booking) {
      id
    }
  }
`;
