import { useCallback } from 'react';
import { useCommonDict } from './useTranslations';
import { ApolloError } from '@apollo/client';
import { useToast } from './useToast';

export const useGraphqlErrorToast = () => {
  const { t } = useCommonDict();
  const toast = useToast();

  return useCallback(
    (error: ApolloError) => {
      if (!error) {
        return;
      }

      if (error?.graphQLErrors && error.graphQLErrors[0]?.extensions?.key) {
        toast.error(
          t(`fpCustomErrors.${error.graphQLErrors[0].extensions.key}`),
        );
        return;
      }

      toast.error(t("fpErrorTitle"), { description: t("fpErrorDesc") });
    },
    [t, toast],
  );
};
