import { gql } from '@apollo/client';
import { GET_BOOKING_FRAGMENT } from './fragments/get-booking-fragment';

export const GET_BOOKINGS_FOR_USER_QUERY = gql`
  ${GET_BOOKING_FRAGMENT}
  query UserBookings($userId: String!, $fromDate: String!) {
    userBookings(userId: $userId, fromDate: $fromDate) {
      ...bookingInstant
    }
  }
`;
