import { TheTime } from "~/_libs/time/TheTime";
import { Timespan } from "~/_libs/time/Timespan";
import { DayOccupation, RangeBookingUnit } from "~/types/Resource";

export const isRangeSelected = (
  selectedTime: Timespan,
  range: RangeBookingUnit,
) => {
  const isSelectSet = selectedTime?.fromTime && selectedTime?.toTime;
  if (!isSelectSet) {
    return false;
  }

  const { fromTime, toTime } = selectedTime;
  const { durationInMinutes, startTime } = range;

  const rangeStartTime = new TheTime(startTime.hour, startTime.minute);
  const rangeEndTime = rangeStartTime.addMinutes(durationInMinutes);

  return fromTime.isEqual(rangeStartTime) && toTime.isEqual(rangeEndTime);
};

export const getRange = (
  ranges: RangeBookingUnit[],
  selectedTime: Timespan,
) => {
  return ranges.find((range) => isRangeSelected(selectedTime, range));
};

const isInRange = (selectedTime: Timespan, range: RangeBookingUnit) => {
  const isSelectSet = selectedTime?.fromTime && selectedTime?.toTime;
  if (!isSelectSet || range.isUnavailable) {
    return false;
  }

  const { fromTime, toTime } = selectedTime;
  const { durationInMinutes, startTime } = range;

  const rangeStartTime = new TheTime(startTime.hour, startTime.minute);
  const rangeEndTime = rangeStartTime.addMinutes(durationInMinutes);

  return (
    TheTime.isWithinInterval(fromTime, {
      start: rangeStartTime,
      end: rangeEndTime,
    }) &&
    TheTime.isWithinInterval(toTime, {
      start: rangeStartTime,
      end: rangeEndTime,
    })
  );
};

const isExactInRange = (selectedTime: Timespan, range: RangeBookingUnit) => {
  const isSelectSet = selectedTime?.fromTime && selectedTime?.toTime;
  if (!isSelectSet || range.isUnavailable) {
    return false;
  }

  const { fromTime, toTime } = selectedTime;

  const { durationInMinutes, startTime } = range;
  const rangeStartTime = new TheTime(startTime.hour, startTime.minute);
  const rangeEndTime = rangeStartTime.addMinutes(durationInMinutes);

  return fromTime.isEqual(rangeStartTime) && toTime.isEqual(rangeEndTime);
};

const getEstimatedRange = (
  ranges: RangeBookingUnit[],
  selectedTime: Timespan,
) => {
  return ranges.find((range) => isInRange(selectedTime, range));
};

const getExactRange = (ranges: RangeBookingUnit[], selectedTime: Timespan) => {
  return ranges.find((range) => isExactInRange(selectedTime, range));
};

export const getCorrectedTime = (
  ranges: RangeBookingUnit[],
  selectedTime: Timespan,
): Timespan => {
  if (!selectedTime?.fromTime && !selectedTime?.toTime && ranges.length === 1) {
    const range = ranges[0];

    if (range.isUnavailable) {
      return null;
    }

    const newFromDate = new TheTime(
      range.startTime.hour,
      range.startTime.minute,
    );

    const newToDate = newFromDate.addMinutes(range.durationInMinutes);
    return {
      fromTime: newFromDate,
      toTime: newToDate,
    };
  }

  if (!selectedTime?.fromTime || !selectedTime?.toTime) {
    return null;
  }

  let newRange = null;

  newRange = getExactRange(ranges, {
    fromTime: selectedTime.fromTime,
    toTime: selectedTime.toTime,
  });

  if (!newRange) {
    newRange = getEstimatedRange(ranges, {
      fromTime: selectedTime.fromTime,
      toTime: selectedTime.toTime,
    });
  }

  if (!newRange) {
    return null;
  }

  const newFromDate = new TheTime(
    newRange.startTime.hour,
    newRange.startTime.minute,
  );

  const newToDate = newFromDate.addMinutes(newRange.durationInMinutes);
  return {
    fromTime: newFromDate,
    toTime: newToDate,
  };
};

export const getVerifiedTimeValues = (
  dayOccupation: DayOccupation[],
  selectedTime: Timespan,
) => {
  const hasAny = dayOccupation?.length > 0;
  if (!hasAny) {
    return null;
  }

  if (!selectedTime?.fromTime || !selectedTime?.toTime) {
    return null;
  }

  const { fromTime, toTime } = selectedTime;
  const isValidTimeSelection = dayOccupation.some((occupation) => {
    const start = TheTime.parse(occupation.interval.startDate);
    const end = TheTime.parse(occupation.interval.endDate);

    const isInInterval =
      TheTime.isWithinInterval(fromTime, { start, end }) &&
      TheTime.isWithinInterval(toTime, { start, end });

    if (isInInterval) {
      return occupation.status === "AVAILABLE";
    }

    return false;
  });

  if (!isValidTimeSelection) {
    return null;
  }

  return selectedTime;
};
