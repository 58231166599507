import { Reducer, useReducer } from "react";
import { Resource } from "~/types/Resource";
import { validateResource } from "./validation";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { BookingUnitMode } from "~/types/BookingUnitMode";
import { getCorrectedTime, getVerifiedTimeValues } from "./types/range-helpers";
import { TheDay } from "~/_libs/time/TheDay";
import { TheTime } from "~/_libs/time/TheTime";
import { Timespan } from "~/_libs/time/Timespan";

type UserData = {
  paymentMethodId: string;
  organizationId: string;
};

type BookingData = {
  workspaceId?: string;
  resource?: Resource;
  date?: TheDay;
  time?: Timespan;
};

type State = {
  canBook: boolean;
  isCreated: boolean;
  user?: UserData;
  booking?: BookingData;
  isFastBooking?: boolean;
};

type Action =
  | {
      type: "bookingDataInitialized";
      bookingData: {
        resource?: Resource;
        date?: string;
        time?: {
          fromTime?: string;
          toTime?: string;
        };
      };
    }
  | {
      type: "bookingFlowStarted";
      workspaceId: string;
      resourceId: string;
      date?: TheDay;
      from?: TheTime;
      to?: TheTime;
    }
  | {
      type: "recreateBookingFlowStarted";
      workspaceId: string;
      resourceId: string;
      date?: TheDay;
      from?: TheTime;
      to?: TheTime;
    }
  | {
      type: "bookingFlowRecreated";
      workspaceId: string;
      resource: Resource;
      date?: TheDay;
      from?: TheTime;
      to?: TheTime;
    }
  | { type: "workspaceChanged"; workspaceId: string }
  | { type: "resourceUpdated"; resource: Resource }
  | { type: "userDataChanged"; userData: UserData }
  | { type: "dateChanged"; date: TheDay }
  | { type: "timeSelectionChanged"; start: TheTime; end: TheTime }
  | { type: "bookingFlowStopped" }
  | { type: "bookingCreated" }
  | { type: "flowCleared" };

export function bookingFlowReducer(state: State, action: Action) {
  switch (action.type) {
    case "bookingDataInitialized": {
      let date = TheDay.parse(action.bookingData?.date);
      if (date.isBeforeToday()) {
        date = TheDay.now();
      }

      let time = null;
      if (
        action.bookingData?.time?.fromTime &&
        action.bookingData?.time?.toTime
      ) {
        time = {
          fromTime: TheTime.parse(action.bookingData.time.fromTime),
          toTime: TheTime.parse(action.bookingData.time.toTime),
        };

        time = getVerifiedTimeValues(
          action.bookingData.resource?.availability?.dayOccupation,
          time,
        );
      }

      return {
        ...state,
        booking: { ...action.bookingData, date, time },
      };
    }
    case "bookingFlowStarted": {
      if (!action.resourceId) {
        throw new Error(
          "Booking flow cannot be started with a resource without an id.",
        );
      }

      let newSelectedDate = TheDay.now();
      if (action.date) {
        newSelectedDate = action.date;
      } else if (state.booking?.date) {
        newSelectedDate = state.booking.date;
      }

      return {
        ...state,
        isCreated: false,
        isFastBooking: true,
        booking: {
          date: newSelectedDate,
          workspaceId: action.workspaceId,
          resource: { id: action.resourceId } as Resource,
          time: {
            fromTime: action.from,
            toTime: action.to,
          },
        } as BookingData,
      };
    }
    case "recreateBookingFlowStarted": {
      if (!action.resourceId) {
        throw new Error(
          "Booking flow cannot be started with a resource without an id.",
        );
      }

      let newSelectedDate = TheDay.now();
      if (action.date) {
        newSelectedDate = action.date;
      } else if (state.booking?.date) {
        newSelectedDate = state.booking.date;
      }

      return {
        ...state,
        isCreated: false,
        isFastBooking: false,
        booking: {
          date: newSelectedDate,
          workspaceId: action.workspaceId,
          resource: { id: action.resourceId } as Resource,
          time: {
            fromTime: action.from,
            toTime: action.to,
          },
        } as BookingData,
      };
    }
    case "bookingFlowRecreated": {
      if (!action.resource?.id) {
        throw new Error(
          "Booking flow cannot be started with a resource without an id.",
        );
      }

      if (state.booking?.resource?.id === action.resource?.id) {
        return state;
      }

      const resourceCopy = cloneDeep(action.resource);

      let newSelectedDate = TheDay.now();
      if (action.date) {
        newSelectedDate = action.date;
      } else if (state.booking?.date) {
        newSelectedDate = state.booking.date;
      }

      return {
        ...state,
        isCreated: false,
        isFastBooking: false,
        booking: {
          date: newSelectedDate,
          workspaceId: action.workspaceId,
          resource: resourceCopy,
          time: {
            fromTime: action.from,
            toTime: action.to,
          },
        },
      };
    }
    case "userDataChanged": {
      const isEqual =
        state?.user?.paymentMethodId === action.userData?.paymentMethodId &&
        state?.user?.organizationId === action.userData?.organizationId;

      if (isEqual) {
        return state;
      }

      return {
        ...state,
        canBook: !!action.userData?.paymentMethodId,
        user: action.userData,
        isCreated: false,
      };
    }
    case "resourceUpdated": {
      if (!state.booking?.resource) {
        return state;
      }

      validateResource(action.resource);
      const resourceCopy = cloneDeep(action.resource);

      const isSame = resourceCopy?.id === state.booking?.resource?.id;
      if (!isSame) {
        throw new Error(
          `Resource id mismatch on update - old: ${state?.booking?.resource?.id} and new: ${resourceCopy?.id}`,
        );
      }

      if (isEqual(action.resource, state.booking?.resource)) {
        return state;
      }

      let timeValues: Timespan = {
        fromTime: state.booking?.time?.fromTime,
        toTime: state.booking?.time?.toTime,
      };

      if (action.resource.id === state.booking.resource?.id) {
        const isRanged =
          action.resource?.bookingUnitMode ===
          BookingUnitMode.BOOKING_UNIT_MODE_RANGES;

        if (isRanged) {
          timeValues = getCorrectedTime(
            action.resource?.rangeBookingUnitList,
            timeValues,
          );
        }
      }

      timeValues = getVerifiedTimeValues(
        action.resource?.availability?.dayOccupation,
        timeValues,
      );

      return {
        ...state,
        booking: {
          ...state.booking,
          resource: resourceCopy,
          time: timeValues,
        },
      };
    }
    case "workspaceChanged": {
      if (state.booking?.workspaceId === action.workspaceId) {
        return state;
      }

      return {
        ...state,
        isFastBooking: false,
        booking: {
          ...state.booking,
          workspaceId: action.workspaceId,
        },
      };
    }
    case "timeSelectionChanged": {
      const isOldStart = TheTime.areEqual(
        state.booking?.time?.fromTime,
        action.start,
      );
      const isOldEnd = TheTime.areEqual(
        state.booking?.time?.toTime,
        action.end,
      );
      if (isOldStart && isOldEnd) {
        return state;
      }

      const isRanged =
        state.booking?.resource?.bookingUnitMode ===
        BookingUnitMode.BOOKING_UNIT_MODE_RANGES;
      if (isRanged) {
        const newTime = {
          fromTime: action.start,
          toTime: action.end,
        };

        const correctedTime = getCorrectedTime(
          state.booking?.resource?.rangeBookingUnitList,
          newTime,
        );

        return {
          ...state,
          booking: {
            ...state.booking,
            time: correctedTime,
          },
        };
      }

      return {
        ...state,
        booking: {
          ...state.booking,
          time: {
            fromTime: action.start,
            toTime: action.end,
          },
        },
      };
    }

    case "dateChanged": {
      const { date } = action;

      if (TheDay.areEqual(date, state.booking?.date)) {
        return state;
      }

      return {
        ...state,
        booking: {
          ...state.booking,
          time: null as Timespan,
          date,
        },
      };
    }
    case "bookingFlowStopped": {
      const { date, workspaceId } = state.booking;
      return {
        ...state,
        isFastBooking: null,
        isCreated: false,
        booking: {
          date,
          workspaceId: state.isFastBooking ? null : workspaceId,
        },
      };
    }
    case "bookingCreated": {
      return {
        ...state,
        isCreated: true,
        isFastBooking: null as boolean,
      };
    }
    case "flowCleared": {
      const { date } = state.booking;
      return {
        ...state,
        isFastBooking: null,
        isCreated: false,
        booking: { date },
      };
    }
  }
}

export const useBookingFlowRedux = () => {
  return useReducer<Reducer<State, Action>>(bookingFlowReducer, {
    canBook: false,
    isCreated: false,
  });
};
