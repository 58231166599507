/**
 * NOTE - these are only the client side events
 * other events are tracked on the server side
 * such as booking initiated, booking completed, user followed, calendar created, etc
 */

export const TRACKING_EVENTS: {
  [key: string]: string;
} = {
  FINISH_BOOKING_CLICKED: "Finish Booking Clicked",
  BOOKING_INITIATED: "Booking Initiated",
  OPENED_APP: "App Opened",
  USER_SIGNEDUP: "User Signedup",
  BOOKMARK_ADDED: "Bookmark Added",
  BOOKMARK_REMOVED: "Bookmark Removed",
  ACCESSKEY_CLICKED: "Accesskey Clicked",
  BOOKING_SHARED: "Booking Shared",
  SHARE_CLICKED: "Share Clicked",
};
