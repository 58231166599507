import { gql } from "@apollo/client";

export const GET_BOOKING_FRAGMENT = gql`
  fragment bookingInstant on Booking {
    id
    actualPrice
    cancelledAt
    digitalKeys {
      key
      provider
    }
    durationInMinutes
    isCancellable
    cancellationPolicyId
    resource {
      id
      name
      vatPercentage
      resourceType
      capacity
      seats
    }
    startDateTime
    workspace {
      id
      name
      currency
      address {
        city
        address
        countryISO
        latlng {
          latitude
          longitude
        }
      }
    }
  }
`;
