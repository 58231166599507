import { BookingUnitMode } from "~/types/BookingUnitMode";
import { Resource } from "~/types/Resource";

export const validateResource = (resource: Resource) => {
  const { bookingUnitMode, bookingType } = resource;

  if (!bookingType) {
    throw new Error(`No booking type for ${resource?.id} resource`);
  }

  if (bookingUnitMode === BookingUnitMode.BOOKING_UNIT_MODE_HOURLY) {
    const isDefined = !!resource?.hourlyBookingUnit;
    if (!isDefined) {
      throw new Error(
        `Hourly booking data not provided for ${resource?.id} resource`,
      );
    }
  } else if (bookingUnitMode === BookingUnitMode.BOOKING_UNIT_MODE_RANGES) {
    const isDefined = resource?.rangeBookingUnitList?.length > 0;
    if (!isDefined) {
      throw new Error(
        `Range booking data not provided for ${resource?.id} resource`,
      );
    }
  } else {
    throw new Error(
      `Booking unit mode not provided for ${resource?.id} resource`,
    );
  }
};
