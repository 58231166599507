import { gql } from '@apollo/client';
import { BookingInput } from './create-booking.gql';

export type BookingRequestInput = BookingInput;

export type CreateBookingRequestMutationInputType = {
  bookingRequest: BookingRequestInput;
};

export type CreateBookingRequestMutationType = {
  createBookingRequest: { id: string };
};

export const CREATE_BOOKING_REQUEST_MUTATION = gql`
  mutation CreateBookingRequest($bookingRequest: BookingRequestInput!) {
    createBookingRequest(bookingRequest: $bookingRequest) {
      id
    }
  }
`;
